<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>

                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div>
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Job Title</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Score</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="applicants.docs.length>0">
                        <tr v-for="(applicant, i) in applicants.docs" :key="i">
                            <td>{{applicant.id}}</td>
                            <td>{{applicant.job_title}}</td>
                            <td>{{fullName(applicant.user)}}</td>
                            <td>{{applicant.user.phone_number || '-'}}</td>
                            <td>{{applicant.status}}</td>
                            <!-- <td><label-status :type="'applicant'" :status="applicant.status" /></td> -->
                            <td>{{applicant.score || '-'}}</td>
                            <td>{{getDate(applicant.created_at)}}</td>
                            <td>
                                <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-danger" @click="showModalScore(applicant.id)">Set Score</button><br/>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="3"/>
                </table>
            </div>
            <pagination
                :total-data="applicants.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="set-score" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Set Applicant Score</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Score</label>
                        <div class="uk-width-1-1 uk-inline">
                            <input
                                class="uk-input"
                                type="text"
                                placeholder="Set score"
                                v-model="score"
                                name="score"
                                v-validate="'required'"
                                :class="{'uk-form-danger': errors.has('score')}">
                        </div>
                        <span v-show="errors.has('score')" class="uk-text-small uk-text-danger">{{ errors.first('score') }}</span>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="hideModal">Cancel</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="submitSetScore">Set</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import formatter from "@/utils/formatter";
import { notificationSuccess } from '@/utils/notification';
// import LabelStatus from '@/components/globals/LabelStatus';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1
            },
            select_applicant: null,
            score: null
        };
    },
    components: {
        Pagination,
        EmptyTable,
        // LabelStatus
    },
    computed : {
        ...mapGetters({
            applicants: 'applicant/applicants'
        })
    },
    mounted() {
        this.getApplicant({job_id:this.$route.params.job_id, data:this.meta});
    },
    methods: {
        ...mapActions({
            getApplicant: 'applicant/getApplicant',
            setApplicantScore: 'applicant/setApplicantScore'
        }),
        getDate(data) {
            return formatter.dateComplete(data);
        },
        fullName(data) {
            return formatter.getFullname(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModalScore(applicant_id) {
            this.select_applicant = applicant_id;
            await window.UIkit.modal('#set-score').show();
        },
        async hideModal() {
            await window.UIkit.modal('#set-score').hide();
            this.select_applicant = null;
            this.score = null;
            this.$validator.reset();
        },
        submitSetScore() {
            this.$validator.validateAll().then(async (success) => {
                if(success) {
                    if (this.$validator.errors.any()) return;
                    const response = await this.setApplicantScore({applicant_id: this.select_applicant, score:this.score});
                    if (response === 'OK') {
                        this.getApplicant({job_id:this.$route.params.job_id, data:this.meta});
                        await window.UIkit.modal('#set-score').hide();
                        notificationSuccess('Success set score');
                    }
                }
            });
        }
    },
};
</script>
